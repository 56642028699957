function layout() {
    if (window.matchMedia('(max-width: 768px)').matches) {
        return;
    }
    $containersRight = $('.container:not(".container--fullwidth-right")');
    $containersLeft = $('.container:not(".container--fullwidth-left")');
    $marginLeft = $containersRight.first().offset().left;
    if ($containersRight && $containersRight.length > 0) {
        $('.container--fullwidth-right').css('width', $('.container').first().outerWidth() + $marginLeft);
    }

    if ($containersLeft && $containersLeft.length > 0) {
        $marginRight = $containersLeft.first().offset().right;
        $('.container--fullwidth-left').css('width', $('.container').first().outerWidth() + $marginLeft);
    }
}

function initializeNavTabs(elemWidth, elemPos) {
    try {
      if(elemPos.left <= 0 ){
        $('.hoverline-tab hr').css({ 'width': 0, left: elemPos.left });        
      }
      else{
        $('.hoverline-tab hr').css({ 'width': elemWidth, left: elemPos.left });
      }
    } catch (error) {
        return error;
    }
}
const home = $('#home') 
const about = $('#about')
const service = $('#service')
const property = $('#property') 
const news = $('#news')
const contact = $('#contact')

 function sentenceCase(input, lowercaseBefore) {
  input = ( input === undefined || input === null ) ? '' : input;
  if (lowercaseBefore) { input = input.toLowerCase(); }
  return input.toString().replace( /(^|\. *)([a-z])/g, function(match, separator, char) {
      return separator + char.toUpperCase();
  });
}

$(document).ready(function() {
    var x = document.querySelectorAll("[class='fc']");
    for (var i=0;i<x.length;i++) {
        var a = x[i].innerText.toString()
        x[i].innerText = sentenceCase(a.toLowerCase())
    }
    $(window).on('resize', function(){
      layout();
      if('/'==url){
        // console.log($('#hl'))
        initializeNavTabs(home.width(), home.position());
        $('#hl').css('background','#2e75b5')
      }
      else if('/about'==url){
        initializeNavTabs(about.width() , about.position());
        $('#hl').css('background','white')
      }
      else if('/services'==url){
        $('#hl').css('background','#2e75b5')
        initializeNavTabs(service.width() ,service.position());
      }
      else if('/property-locator'==url||'/property-locator'==url.substring(0,17)){
        $('#hl').css('background','white')        
        initializeNavTabs(property.width(),property.position());
      }
      else if('/newsevents'==url||'/newsevents'==url.substring(0,11)){
        initializeNavTabs(news.width(),news.position());
        $('#hl').css('background','white')

      }
      else if('/#contact'){
        initializeNavTabs(contact.width() , contact.position());
        $('#hl').css('background','#2e75b5')
    }  
  })


    var url = window.location.pathname
    if('/'==url){
        // console.log($('#hl'))
        initializeNavTabs(home.width(), home.position());
        $('#hl').css('background','#2e75b5')
    }
    else if('/about'==url){
      initializeNavTabs(about.width() , about.position());
      $('#hl').css('background','white')
    }
    else if('/services'==url){
      $('#hl').css('background','#2e75b5')
      initializeNavTabs(service.width() ,service.position());
    }
    else if('/property-locator'==url||'/property-locator'==url.substring(0,17)){
      $('#hl').css('background','white')        
      initializeNavTabs(property.width(),property.position());
    }
    else if('/newsevents'==url||'/newsevents'==url.substring(0,11)){
      initializeNavTabs(news.width(),news.position());
      $('#hl').css('background','white')

    }
    else if('/#contact'){
      initializeNavTabs(contact.width() , contact.position());
      $('#hl').css('background','#2e75b5')

    }   
    $(".hoverline-tab li").on({
        mouseenter: function () {
            var elemWidth = $(this).width();
            var elemPos = $(this).position();
            // console.log(elemWidth,elemPos)
            initializeNavTabs(elemWidth, elemPos);
        },
        mouseleave: function () {   
          if('/'==url){
            // console.log($('#hl'))
            initializeNavTabs(home.width(), home.position());
            $('#hl').css('background','#2e75b5')
          }
          else if('/about'==url){
            initializeNavTabs(about.width() , about.position());
            $('#hl').css('background','white')
          }
          else if('/services'==url){
            $('#hl').css('background','#2e75b5')
            initializeNavTabs(service.width() ,service.position());
          }
          else if('/property-locator'==url||'/property-locator'==url.substring(0,17)){
            $('#hl').css('background','white')        
            initializeNavTabs(property.width(),property.position());
          }
          else if('/newsevents'==url||'/newsevents'==url.substring(0,11)){
            initializeNavTabs(news.width(),news.position());
            $('#hl').css('background','white')
    
          }
          else if('/#contact'){
            initializeNavTabs(contact.width() , contact.position());
            $('#hl').css('background','#2e75b5')
    
        }    
        },
        click: function(e) {
            var url = $(this).data('url');
            
            if (url && url != '') {
                history.pushState("", "", `${url}`);
                setTimeout(function(){
                    initializeNavTabs($(".hoverline-tab  .activate").width(), $(".hoverline-tab  .activate").position());
                }, 300);
            }
        }
    })
})
